
// Libaries
import { Component, namespace, Vue, Watch } from "nuxt-property-decorator";
import { PaymentTypes, PriceValues } from "~/operations/messages";

// Components
import LightBox from "~/components/molecules/display/LightBox.vue";
import LoadingSpinner from "~/components/molecules/display/LoadingSpinner.vue";
import SortHeader from "~/components/molecules/nav/SortHeader.vue";
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import BlogPost from "~/components/molecules/text/BlogPost.vue";
import CityLineChart from "~/components/organisms/chart/CityLineChart.vue";
import CombinedRecommendationCharts from "~/components/organisms/chart/CombinedRecommendationCharts.vue";
import DealerOfferItem from "~/components/organisms/display/DealerOfferItem.vue";
import PriceComparisonH1 from "~/components/organisms/display/PriceComparisonH1.vue";
import PriceComparisonHistoricalData from "~/components/organisms/display/PriceComparisonHistoricalData.vue";
import NewsletterSignup from "~/components/organisms/form/NewsletterSignup.vue";
import PriceComparison from "~/components/organisms/form/PriceComparison.vue";
import PriceNotification from "~/components/organisms/form/PriceNotification.vue";
import ShareNav from "~/components/organisms/nav/ShareNav.vue";
import DynamicTable from "~/components/organisms/table/DynamicTable.vue";
import OilImportTable from "~/components/organisms/table/OilImportTable.vue";
import PriceComparingTable from "~/components/organisms/table/PriceComparingTable.vue";
import sortingDataParemeters from "~/defaults/heizoelpreise/sortingDataParemeters";
import sortOptions from "~/defaults/heizoelpreise/sortOptions";

// Mixins
import { Jsonld } from "nuxt-jsonld";

import { translateErrorMessage } from "~/defaults/heizoelpreise/errorCodeMessages";
import { translateStateName } from "~/mixins/translations";
import { generateMetaTags } from "~/operations/meta-helper-functions";

import BreadCrumb from "~/components/molecules/display/BreadCrumb.vue";
import {
  replaceToUmlauts,
  returnFormattedDate,
  returnPriceFormatDE,
} from "~/mixins/formatting";
import { decodePlaceName, decodeUrlForPlace } from "~/operations/shared";

import CityHistoryChart from "~/components/organisms/chart/CityHistoryChart.vue";

import { createBreadCrumbsStructuredData } from "~/mixins/breadcrumb";
import {
  CityPlace,
  CountyLink,
  SortingDataParemeter,
  SortOption,
} from "~/types/HeatingOilCalculator";
const FederalStatesModule = namespace("federalstates");
const CalculatorModule = namespace("calculator");
const BlogModule = namespace("blog");
const BreadCrumbModule = namespace("breadcrumb");
const PriceComparisonModule = namespace("priceComparison");
const OilpriceModule = namespace("oilprice");

@Jsonld
@Component({
  scrollToTop: true,
  layout: "shop",
  components: {
    LoadingSpinner,
    SortHeader,
    DealerOfferItem,
    PriceComparison,
    CombinedRecommendationCharts,
    BlogPost,
    NewsletterSignup,
    PriceNotification,
    OilImportTable,
    CityHistoryChart,
    ShareNav,
    PriceComparisonH1,
    LightBox,
    AkkordeonListItem,
    BreadCrumb,
    PriceComparingTable,
    DynamicTable,
    PriceComparisonHistoricalData,
    PlaceListSection: () =>
      import("~/components/organisms/display/PlaceListSection.vue"),
    CityLineChart: () =>
      import("~/components/organisms/chart/CityLineChart.vue"),
  },
  methods: {
    translateStateName,
    translateErrorMessage,
    returnPriceFormatDE,
    returnFormattedDate,
  },
})
export default class HeatingOilOrderView extends Vue {
  [x: string]: any;
  // jsonld plugin not working correctly inside ortsites
  // Have to create seperate script tags for structured data
  head() {
    return {
      ...generateMetaTags(this.$route.path, "state-pages", [
        {
          k: "state",
          v: this.city.name,
        },
        {
          k: "zipcode",
          v: this.zipcode,
        },
      ]),
      link: [
        {
          rel: "canonical",
          href: `/heizoelpreise/${this.$route.params.id.toLowerCase()}`,
        },
      ],
      script: [
        {
          json: {
            "@context": "http://schema.org",
            "@type": "FAQPage",
            mainEntity: [
              {
                "@type": "Question",
                name: `Was kostet Heizöl in ${translateStateName(
                  this.$route.params.id,
                )}?`,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: `Pro 100L liegt der günstigste Heizölpreis in ${
                    this.city.name
                  } (${this.city.zipcode}) heute bei ${returnPriceFormatDE(
                    this.getActualPrice(),
                  )} und wird aktuell vom Händler ${
                    this.dealerOffersData.dealerOffers[0]?.dealer.name
                  } angeboten. Der 100L-Heizölpreis bei 3000L-Bestellmenge beträgt in ${
                    this.city.name
                  } (${this.city.zipcode}) aktuell ${returnPriceFormatDE(
                    this.getActualPrice(),
                  )} und liegt ${this.getSeoTextHint(
                    30,
                  )} dem laufenden Durchschnitt der letzten 3 Monate sowie ${this.getSeoTextHint(
                    7,
                  )} dem laufenden Durchschnitt der letzten 7 Tage. Der höchste 100L-Heizölpreis in den letzten 3 Monaten betrug ${returnPriceFormatDE(
                    this.getChartMaxPrice(),
                  )}, der niedrigste Preis für 100L Heizöl betrug ${returnPriceFormatDE(
                    this.getChartMinPrice(),
                  )}. Laut dem esyoil-Tiefpreissystem ist die technische Meinung: ${
                    this.$store.state.schwarmometer.esyoilSignal
                      .buyingIntesionString
                  }. Die durchschnittliche Lieferzeit für Heizöl in ${
                    this.city.name
                  } (${this.city.zipcode}) beträgt ${
                    this.averageDeliveryTime
                  } Tage, die kürzeste Lieferzeit liegt bei ${
                    this.minDeliveryTime
                  } Tagen.`,
                },
              },
            ],
          },
          type: "application/ld+json",
        },
        {
          json: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: createBreadCrumbsStructuredData(this.breadCrumb),
          },
          type: "application/ld+json",
        },
      ],
    };
  }

  @FederalStatesModule.Action("fetchCityZipcodes")
  fetchCityZipcodes;

  @FederalStatesModule.Action("fetchCityName")
  fetchCityName;

  @FederalStatesModule.State("fetchCityName")
  cityName;

  @FederalStatesModule.Action("fetchHistoricalPriceData")
  fetchHistoricalPriceData;

  @FederalStatesModule.State("cityRequestData")
  cityRequestData: any;

  @FederalStatesModule.State("city")
  city: any;

  @FederalStatesModule.State("cityPriceHistory")
  cityPriceHistory: any;

  @FederalStatesModule.Action("fetchOtherZipcodes")
  fetchOtherZipcodes: any;

  @FederalStatesModule.Action("fetchCountyIdentData")
  fetchCountyIdentData: any;

  @FederalStatesModule.State("otherZipcodes")
  _otherZipcodes: any;

  @FederalStatesModule.Action("fetchCommunityLinks")
  fetchCommunityLinks: any;

  @CalculatorModule.State("dealerOffersData")
  dealerOffersData: any;

  @CalculatorModule.State("averageDeliveryTime")
  averageDeliveryTime: any;

  @CalculatorModule.State("minDeliveryTime")
  minDeliveryTime: number;

  @CalculatorModule.Action("fetchDealerOffers")
  _fetchDealerOffers;

  @CalculatorModule.Action("fetchAndSortDealerOffers")
  _fetchAndSortDealerOffers;

  @BlogModule.State("articles")
  articles: any;

  @BreadCrumbModule.State("breadCrumb")
  breadCrumb;

  @BlogModule.Action("fetchNewestBlogPosts")
  fetchNewestBlogPosts;

  @BlogModule.Action("fetchAuthorsData")
  fetchAuthorsData;

  @PriceComparisonModule.State("historyComparePrices")
  historyComparePrices;

  @PriceComparisonModule.State("historyComparePricesTransformed")
  historyComparePricesTransformed;

  @PriceComparisonModule.Action("fetchHistoryPriceDiffData")
  fetchHistoryPriceDiffData;

  @PriceComparisonModule.State("historicalHighestAndLowestPrice")
  historicalHighestAndLowestPrice;

  @PriceComparisonModule.Action("fetchHistoricalHighestAndLowestPrice")
  fetchHistoricalHighestAndLowestPrice;

  @OilpriceModule.State("cityPlaces")
  cityPlaces: any;

  @OilpriceModule.State("cityHistoryChartData")
  cityHistoryChartData: any;

  @OilpriceModule.Action("fetchCityHistoryChartData")
  fetchCityHistoryChartData: any;

  @OilpriceModule.Action("fetchNationalPriceTrend")
  fetchNationalPriceTrend: any;

  @OilpriceModule.Action("fetchCityPlacesData")
  fetchCityPlacesData: any;

  PriceValues = PriceValues;
  PaymentTypes = PaymentTypes;
  sortOptions: SortOption = sortOptions;
  sortingDataParemeters: SortingDataParemeter = sortingDataParemeters;
  showPriceOffers = true;

  cityChanged = false;
  zipcode = "";
  questionList = [];
  isTopTextExpanded = false;
  chartMonths = 3;
  initialChartData = [];

  MAX_ITEMS_LIMIT = 50;

  timeRangeOptions: Array<{ name: string; value: string }> = [
    { name: "3 Monate", value: "3" },
    { name: "6 Monate", value: "6" },
    { name: "1 Jahr", value: "12" },
    { name: "3 Jahre", value: "36" },
  ];

  async fetchCityPlaces(paramId?: string): Promise<void> {
    const cityName = paramId || this.city.name || this.$route.params.id;
    await this.fetchCityPlacesData({ cityName, zipcode: this.zipcode });
  }

  async fetchCounties(statesNutsCode: string): Promise<void> {
    await this.fetchCommunityLinks(statesNutsCode);
  }

  async fetchCountytData(stateName: string, countyName: string): Promise<void> {
    await this.fetchCountyIdentData({
      stateName,
      countyName,
    });
  }

  async handleCountyLinks(): Promise<void> {
    const stateName = this.breadCrumb[0]?.name;
    const countyName = this.breadCrumb[1]?.name;

    if (stateName && countyName) {
      await this.fetchCountytData(stateName, countyName);
      await this.fetchCounties(this.$store.state.federalstates.countyNutsCode);
    }
  }

  async mounted() {
    window["gtag_enable_tcf_support"] = true;
    // Wenn im query eine PLZ ist dann muss vorher deren PLZ gesetzt werden
    if (this.$route.query?.zipcode) {
      this.$store.commit(
        "federalstates/setSingleCityZipcode",
        this.$route.query?.zipcode,
      );
    }
    await this.fetchCityHistoryChartData(this.zipcode);

    await Promise.all([this.loadBlogPostData()]);
  }

  async fetch() {
    this.$store.commit("checkout/resetCheckoutStorage");
    await this.fetchLocationData();

    this.initialChartData = this.cityPriceHistory.chart.data;
    if (!this.cityRequestData.zipcode || !this.zipcode) {
      this.$nuxt.error({ statusCode: 404 });
    }
  }

  async fetchLocationData() {
    let location = this.locationFromUrl;
    if (this.isNewUrlStructure) {
      await this.preflightLocationData();
    } else {
      await this.redirectToCorrectUrlStructure();
    }

    await Promise.all([
      this.fetchHistoryPriceDiffData(this.zipcode),
      this.fetchHistoricalHighestAndLowestPrice(this.zipcode),
      this.fetchHistoricalData(this.zipcode, 3),
      this.fetchDealerOffers(),
      this.fetchCityPlaces(),
      this.fetchNationalPriceTrend(),
      this.fetchOtherZipcodes(this.city.name),
    ]);
    await this.handleCountyLinks();
  }

  async preflightLocationData() {
    // Check if the query parameter 'zipcode' exists
    if (this.$route.query?.zipcode) {
      this.zipcode = this.$route.query?.zipcode as string;
    } else {
      // Extract the zipcode from the URL
      this.zipcode = this.$route.params.id.split("-").pop();
    }

    const location = this.locationFromUrl;
    await this.fetchCityName({
      zipcode: this.zipcode,
      location: replaceToUmlauts(location),
    });

    /**
     * Check if the place from the url matches the decoded city name
     * If not, return a 404 error with a "Not found" message
     */
    if (location !== decodePlaceName(this.city.name)) {
      this.$nuxt.error({ statusCode: 404, message: "Ort nicht gefunden" });
    }
  }

  async redirectToCorrectUrlStructure() {
    await this.loadZipcodeToName();
    if (this.zipcode) {
      this.$nuxt.context.redirect(
        301,
        decodeUrlForPlace(
          `heizoelpreise-${this.$route.params.id}-${this.zipcode}`,
        ),
      );
    }
  }

  async loadZipcodeToName() {
    let _id = this.$route.params.id;
    const name = _id.replace("|", "/");
    await this.fetchCityZipcodes(name);
    this.zipcode = this.city.zipcode;

    if (!this.cityRequestData.zipcode) {
      // try replacing chars in url and refetch zip
      const newName = name
        .replace("ue", "ü")
        .replace("oe", "ö")
        .replace("ae", "ä");
      await this.fetchCityZipcodes(newName);
      if (this.cityRequestData.zipcode) {
        this.zipcode = this.city.zipcode;
      }
    }
  }

  @Watch("zipcode")
  async onParameterSizeChange(newVal, oldVal) {
    if (newVal !== oldVal && newVal) {
      this.$store.commit("checkout/resetCheckoutStorage");
      await this.fetchDealerOffers();
    }
  }

  @Watch("city.name")
  async onCityChange() {
    this.$store.commit("federalstates/clearCounityLinks");
    await this.fetchCityPlaces(this.city.name);
    await Promise.all([
      this.handleCountyLinks(),
      this.fetchHistoryPriceDiffData(
        this.$route.query?.zipcode ?? this.zipcode,
      ),
      this.fetchHistoricalHighestAndLowestPrice(
        this.$route.query?.zipcode ?? this.zipcode,
      ),

      this.fetchCityHistoryChartData(
        this.$route.query?.zipcode ?? this.zipcode,
      ),
    ]);
    this.cityChanged = true;
  }

  returnUnorderedListVariant(contentArray) {
    if (contentArray.length && contentArray.length < 5) {
      return "default";
    }
    return "excerpt";
  }

  returnArticleData(articleData) {
    if (articleData.length !== 0) {
      return articleData;
    }
    return 1;
  }

  returnTableData(tableData: Array<string | number>) {
    if (tableData.length !== 0) {
      return tableData;
    }
    const PlaceholderData = [];
    for (let i = 0; i < 2; i++) {
      const PlaceholderDataItem = [
        "...wird geladen",
        "...wird geladen",
        "...wird geladen",
        "...wird geladen",
      ];
      PlaceholderData.push(PlaceholderDataItem);
    }
    return PlaceholderData;
  }

  hidePriceOffersContent(status: boolean) {
    this.showPriceOffers = status;
  }

  resortData(sortOptions: {
    sortByParameter: string;
    direction: "asc" | "desc";
  }) {
    this.sortingDataParemeters.sortByParameter = sortOptions.sortByParameter;
    this.sortingDataParemeters.direction = sortOptions.direction;
    this.$store.commit("calculator/sortDealerOffers", sortOptions);
  }

  async updateTimeRangeSelection(months) {
    this.chartMonths = Number(months);
    await this.fetchHistoricalData(this.zipcode, this.chartMonths);
  }

  async fetchDealerOffers() {
    await this._fetchDealerOffers(this.getRequestData());
  }

  async fetchAndSortDealerOffers(data) {
    await this._fetchAndSortDealerOffers({
      dealerParameters: data,
      sortOptions: this.sortingDataParemeters,
    });

    // call fetchHistoricalData again if city is new
    if (this.$route.query.zipcode !== data.zipcode && this.cityChanged) {
      await this.fetchHistoricalData(data.zipcode, 3);
      this.cityChanged = false;
    }
  }

  getRequestData() {
    return {
      zipcode: this.$route.query.zipcode ?? this.zipcode,
      amount: Number(this.$route.query.amount ?? 3000),
      unloading_points: Number(this.$route.query.unloading_points ?? 1),
      payment_type: this.$route.query.payment_type ?? "all",
      prod: this.$route.query.prod ?? "normal",
      hose: this.$route.query.hose ?? "fortyMetre",
      short_vehicle: this.$route.query.short_vehicle ?? "withTrailer",
      deliveryTimes: this.$route.query.deliveryTimes ?? "normal",
    };
  }

  async loadBlogPostData() {
    await Promise.all([this.fetchNewestBlogPosts(), this.fetchAuthorsData()]);
  }

  async fetchHistoricalData(zipcode, months) {
    await this.fetchHistoricalPriceData({
      zipcode,
      months,
    });
  }

  getSeoTextHint(days: number) {
    if (this.getActualPrice() <= this.getChartAveragePrice(days)) {
      return "unter";
    }

    return "über";
  }

  getChartAveragePrice(days: number) {
    const list = this.initialChartData.map((ele) => ele.y);
    list.slice(Math.max(list.length - days, 0));
    return list.reduce((a, b) => a + b, 0) / list.length;
  }

  getChartMinPrice() {
    return Math.min(...this.initialChartData.map((ele) => ele.y));
  }

  getChartMaxPrice() {
    return Math.max(...this.initialChartData.map((ele) => ele.y));
  }

  getLastWeekPriceComparisonPercentage() {
    const length = this.initialChartData.length;
    const lastWeekPrice = this.initialChartData[length - 1 - 7]?.y; // price 7 days ago
    const actualPrice = this.getActualPrice();

    return String(this.calcPercentageValue(lastWeekPrice, actualPrice)).replace(
      ".",
      ",",
    );
  }

  getLastWeekPriceComparisonText() {
    const length = this.initialChartData.length;
    const lastWeekPrice = this.initialChartData[length - 1 - 7]?.y;
    const actualPrice = this.getActualPrice();

    return lastWeekPrice <= actualPrice ? "günstiger" : "teurer";
  }

  getNationalPriceComparisonPercentage() {
    return String(
      this.calcPercentageValue(
        this.$store.state.oilprice.stateAssetsPrice.currentPrice.value,
        this.getActualPrice(),
      ),
    ).replace(".", ",");
  }

  getNationalPriceComparisonPrice() {
    return Math.abs(
      this.getActualPrice() -
        this.$store.state.oilprice.stateAssetsPrice.currentPrice.value,
    );
  }

  getNationalPriceComparisonText() {
    return this.getActualPrice() <=
      this.$store.state.oilprice.stateAssetsPrice.currentPrice.value
      ? "günstiger"
      : "teurer";
  }

  getActualPrice() {
    return this.dealerOffersData.dealerOffers[0]?.pricing._100L.brutto;
  }

  calcPercentageValue(a: number, b: number) {
    const result = Math.abs((a / b) * 100 - 100);
    return Math.round(result * 10) / 10;
  }

  getCorrectPeriodNaming(period: number) {
    switch (period) {
      case 12:
        return "3 Monate";
      case 52:
        return "1 Jahr";
      default:
        return `${period} Wochen`;
    }
  }

  getRandomCounties(counties) {
    const filtered = counties.filter(
      (county) => county.name !== this.city.name,
    );
    const shuffled = [...filtered].sort(() => 0.5 - Math.random());
    return shuffled;
  }

  get historicalHighestData() {
    return this.historicalHighestAndLowestPrice?.map((e) => {
      return {
        timeRange: this.getCorrectPeriodNaming(e.timePeriod),
        price: returnPriceFormatDE(e.max),
        date: returnFormattedDate(e.maxDate, "DD.MM.YYYY"),
      };
    });
  }

  get cityPlacesWithoutRequestedCity() {
    return this.cityPlaces.filter((elem) => elem.name !== this.city.name);
  }

  get historicalLowestData() {
    return this.historicalHighestAndLowestPrice.map((e) => {
      return {
        timeRange: this.getCorrectPeriodNaming(e.timePeriod),
        price: returnPriceFormatDE(e.min),
        date: returnFormattedDate(e.minDate, "DD.MM.YYYY"),
      };
    });
  }

  get formattedTitle(): string {
    return `Höchststände und Tiefststände der Heizölpreise in ${this.city.name}`;
  }

  get hideBlog() {
    return process.server;
  }

  get cityZipcode() {
    return this.cityRequestData.zipcode ?? false;
  }

  get showDealerOffers(): boolean {
    return (
      this.dealerOffersData.errorMessage === "" &&
      this.dealerOffersData.dealerOffers.length > 0
    );
  }

  get otherZipcodes() {
    return this._otherZipcodes.filter((zipcode) => zipcode !== this.zipcode);
  }

  get otherZipcodesLinks() {
    const location = this.$route.params.id.split("-").slice(1, -1).join("-");
    const shuffled = [...this.otherZipcodes].sort(() => 0.5 - Math.random());
    const limited = shuffled.slice(0, 25);

    return limited.map((zipcode) => ({
      name: `${zipcode} ${this.city.name}`,
      link: `heizoelpreise-${location}-${zipcode}`,
    }));
  }

  get randomCountyLinks(): CountyLink[] {
    return this.getRandomCounties(
      this.$store.state.federalstates.communityLinks,
    );
  }

  get limitedCityPlaces(): CityPlace[] {
    return this.cityPlacesWithoutRequestedCity.slice(0, this.MAX_ITEMS_LIMIT);
  }

  get limitedRandomCountyLinks(): CountyLink[] {
    return this.randomCountyLinks.slice(0, this.MAX_ITEMS_LIMIT);
  }

  get cityPlacesExceedsLimit(): boolean {
    return this.cityPlacesWithoutRequestedCity.length > this.MAX_ITEMS_LIMIT;
  }

  get randomCountyLinksExceedsLimit(): boolean {
    return this.randomCountyLinks.length > this.MAX_ITEMS_LIMIT;
  }

  get isNewUrlStructure() {
    return this.$route.params.id.startsWith("heizoelpreise-");
  }

  get locationFromUrl() {
    return this.$route.params.id.split("-").slice(1, -1).join("-");
  }
}
